import React, { useState } from "react";
import "./CarPool.scss";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CarPoolCard from "./CarPoolCard";

export default function CarPool() {
  const [tab, setTab] = useState(1);
  const tab1 = (
    <div className="carpool-cards">
      <CarPoolCard stops={["A", "B", "C"]} date={new Date()}></CarPoolCard>
      <CarPoolCard stops={["A", "B", "C"]} date={new Date()}></CarPoolCard>
      <CarPoolCard stops={["A", "B", "C"]} date={new Date()}></CarPoolCard>
    </div>
  );
  const tab2 = (
    <div className="carpool-cards">
      <CarPoolCard stops={["E", "F", "G"]} date={new Date()}></CarPoolCard>
      <CarPoolCard stops={["E", "F", "G"]} date={new Date()}></CarPoolCard>
      <CarPoolCard stops={["E", "F", "G"]} date={new Date()}></CarPoolCard>
      <CarPoolCard stops={["E", "F", "G"]} date={new Date()}></CarPoolCard>
    </div>
  );

  return (
    <Paper style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button
          color={tab === 1 ? "secondary" : undefined}
          size="large"
          onClick={() => setTab(1)}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
        >
          多伦多拼车
        </Button>
        <Button
          color={tab === 2 ? "secondary" : undefined}
          size="large"
          onClick={() => setTab(2)}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
        >
          其它城市拼车
        </Button>
      </div>
      {tab === 1 ? tab1 : tab2}
      <div className="perfect-centre" style={{ padding: "35px 0 20px 0" }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{ fontWeight: "bold", fontSize: "1.1rem" }}
        >
          全 部 拼 车 信 息
        </Button>
      </div>
    </Paper>
  );
}
