import React from "react";
import "./TravelCard.scss";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

export default function TravelCard() {
  return (
    <Card className="travel-card">
      <CardActionArea>
        <CardMedia
          style={{ height: "200px" }}
          image="https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80&auto=format&fit=crop"
          title="image1"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Title
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Lorem ipsum dolor sit amet, autem mundi phaedrum vis an, cum utroque
            phaedrum sententiae at. Cum an sint hendrerit. Quidam reprehendunt
            has ea, eius lorem te vix, nec in eius veritus oportere. Mea at
            prompta posidonium, sed ut qualisque appellantur definitionem,
            tantas debitis eu mel.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
