import React from "react";
import "./App.scss";
import { Route } from "react-router-dom";
import Home from "./Home";

export default function App() {
  return (
  <div className="App">
    <Route exact path="/" component={Home}></Route>
  </div>
  );
}

