import React, { useEffect, useState, useRef } from "react";
import "./SlideShow.scss";
import { useTransition, animated, config } from "react-spring";
import Paper from "@material-ui/core/Paper";

const urls = [
  "photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i",
  "photo-1544572571-ab94fd872ce4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1534&q=80",
  "reserve/bnW1TuTV2YGcoh1HyWNQ_IMG_0207.JPG?ixlib=rb-1.2.1&w=1534&q=80",
  "photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80",
];

export default function SlideShow() {
  const [index, setIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const speed = 4000;
  const size = urls.length;
  const ref = useRef();

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  useEffect(() => {
    setTimeout(() => setIndex((index + 1) % size), speed);
  }, [index, size]);

  const onResize = () => {
    setHeight(ref.current ? ref.current.offsetWidth * 0.6 : 0);
  };

  useEffect(() => {
    onResize();
  }, []);

  const transitions = useTransition(urls[index], (p) => p, {
    rom: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  return (
    <Paper elevation={3} id="slide-show" ref={ref} style={{ height: height }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={{
            ...props,
            backgroundImage: `url(https://images.unsplash.com/${item}&auto=format&fit=crop)`,
          }}
        ></animated.div>
      ))}
    </Paper>
  );
}
