import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";

export default function FlightPickup() {
  const [date, setDate] = useState(new Date());
  const [flightNum, setFlightNum] = useState("");
  const [tab, setTab] = useState(1);

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleFlightChange = (e) => {
    setFlightNum(e.target.value);
  };

  return (
    <Paper style={{ padding: "20px" }}>
      <div className="space-around">
        <Button
          color={tab === 1 ? "secondary" : undefined}
          size="large"
          onClick={() => setTab(1)}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
        >
          可拼车送机
        </Button>
        <Button
          color={tab === 2 ? "secondary" : undefined}
          size="large"
          onClick={() => setTab(2)}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
        >
          可拼车接机
        </Button>
        <Button
          color={tab === 3 ? "secondary" : undefined}
          size="large"
          onClick={() => setTab(3)}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
        >
          专车送机
        </Button>
      </div>
      <form autoComplete="off">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            format="MM/dd/yyyy"
            id="flight-date"
            label="Flight Date"
            value={date}
            onChange={handleDateChange}
            margin="normal"
          />
        </MuiPickersUtilsProvider>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="flight-number">Flight Number</InputLabel>
          <Input
            id="flight-number"
            label="Flight Number"
            value={flightNum}
            onChange={handleFlightChange}
            endAdornment={
              <InputAdornment position="end">
                <FlightTakeoffIcon
                  style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "10px" }}
                ></FlightTakeoffIcon>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      <div className="perfect-centre" style={{ padding: "35px 0 20px 0" }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{ fontWeight: "bold", fontSize: "1.1rem" }}
        >
          下 一 步
        </Button>
      </div>
    </Paper>
  );
}
