import React from "react";
import SlideShow from "./SlideShow";
import "./Home.scss";
import TravelCard from "./TravelCard";
import FlightPickup from "./FlightPickup";
import CarPool from "./CarPool";

export default function Home() {
  return (
    <div id="home">
      <div className="section">
        <div className="title">
          <h1>LETT GO TRAVEL 欢迎您!</h1>
        </div>
        <div className="perfect-centre">
          <SlideShow></SlideShow>
        </div>
      </div>
      <div className="section">
        <div className="title">
          <h1>一日游</h1>
          <h3>中文服务，本地司机，豪华商务车！</h3>
        </div>
        <div className="flex-cards">
          <TravelCard></TravelCard>
          <TravelCard></TravelCard>
          <TravelCard></TravelCard>
          <TravelCard></TravelCard>
          <TravelCard></TravelCard>
        </div>
      </div>
      <div className="section">
        <div className="title">
          <h1>预约接送机</h1>
          <h3>请提前24小时在此预约，小于24小时只能人工预订</h3>
        </div>
        <FlightPickup></FlightPickup>
      </div>
      <div className="section">
        <div className="title">
          <h1>拼车专区</h1>
          <h3>我们提倡绿色出行，提倡拼车出行！</h3>
        </div>
        <CarPool></CarPool>
      </div>
    </div>
  );
}
