import React from "react";
import "./CarPoolCard.scss";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Dot from "@material-ui/icons/FiberManualRecord";
import RoomIcon from "@material-ui/icons/Room";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

export default function CarPoolCard(props) {
  const { date, stops } = props;

  return (
    <Card className="carpool-card">
      <CardContent>
        <div className="space-around">
          <Typography gutterBottom variant="h6" component="h5" align="center">
            {`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`}
          </Typography>
          <Typography gutterBottom variant="h6" component="h5" align="center">
            {`${date.getHours()}:${date.getMinutes()}`}
          </Typography>
        </div>
        <div className="space-around">
          {stops.map((stop, index) => {
            return (
              <React.Fragment key={stop + "-" + index}>
                <div style={{ textAlign: "center" }}>
                  <div>
                    {index + 1 === stops.length ? (
                      <RoomIcon style={{ color: "red" }}></RoomIcon>
                    ) : (
                      <Dot style={{ color: "red" }}></Dot>
                    )}
                  </div>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="p"
                    align="center"
                    style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                  >
                    {stop}
                  </Typography>
                </div>
                {index + 1 === stops.length || (
                  <ArrowRightAltIcon fontSize="large"></ArrowRightAltIcon>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </CardContent>
      <CardActions className="space-around">
        <Button variant="contained" color="primary">
          告诉朋友
        </Button>
        <Button variant="contained" color="primary">
          我要拼车
        </Button>
      </CardActions>
    </Card>
  );
}
